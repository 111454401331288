import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

export default class P2PDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sourceAccountNumber: "",
      settlementID: "202241220039791700002",
    };
    this.handleChange = this.handleChange.bind(this);
    // this.sendSms = this.sendSms.bind(this);
  }

  //   getUser

  // updateLoan
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //   componentDidMount(){
  //     this.getUserLoanDetails()
  //   }

  handleResolveDeposit = () => {
    window.confirm("Are you sure you wish to update this data ? ")
    this.setState({
      isLoading: true,
    });
    const {sourceAccountNumber,settlementID} =this.state
    console.log("STATE:: ", this.state)

    // return
    axios
      .post(ConstantVar.API_BASE_URL + `/support/activities/resolve_providus_deposit`, {
        sourceAccountNumber,settlementID,
        token: localStorage.getItem("supportToken"),
      })
      .then((res) => {
        console.log("STATE-=res:: ",res)

        this.setState({
          isLoading: false,
        });
          alert(res.data.message);
          return;
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">P2P Deposit</h2>
              <div className="p-3 col-md-7 mx-auto">
                {/* <div className="d-flex items-center "> */}

                <div className="mr-2 mb-0 form-group mt-1">
                  <label>Settlement ID</label>
                    <input
                      type="number"
                      className="form-control"
                      name="settlementID"
                      placeholder="Enter Settlement ID"
                      value={this.state.settlementID}
                      onChange={this.handleChange}
                    />
                </div>
                <div className="ml-2 form-group mt-1">
                  <label>Depositor's Account number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="sourceAccountNumber"
                    placeholder="Enter Depositor's Number"
                    value={this.state.sourceAccountNumber}
                    onChange={this.handleChange}
                  />
                </div>
                {/* </div> */}
                <button 
                  className="btn btn-primary text-center"
                  onClick={this.handleResolveDeposit}
                  disabled={this.state.isLoading}
                  >
                  Update Deposit
                </button>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    );
  }
}
