import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

export default class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
    };
  }

  handleCardDelete = (data) => {
    let confirm = window.confirm("Are you sure you wish to delete this card ? ");
    if (!confirm) return;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/user/card", {
        token: localStorage.getItem("supportToken"),
        email: data.email,
        authorization: data.authorization,
      })
      .then((res) => {
        let response = res.data;
        if (response.status == "success") {
          const newCards = this.props.user.cards.filter((item) => item.id != data.id);
          this.props.removeCard(newCards);
          // this.setState({disableButton:true})

          // this.getUserDetails();
          window.alert(response.message);
          // () => {
        } else {
          window.alert(response.message);
        }
      });
  };

  render() {
    let clearance = localStorage.getItem("supportClearance");
    return (
      <div
        className="tab-pane fade show active"
        id="nav-bank-accounts"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0">Bank Accounts</h3>
                <span className="float-right">
                  <h3>
                    <small>Manually Linked Banks: </small>
                    {this.props.user._linked_banks}
                  </h3>
                </span>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              {(() => {
                if (this.props.user.bank_accounts.length === 0)
                  return (
                    <div className="p-5">
                      <div className="text-center">
                        <h3>No bank accounts for user</h3>
                      </div>
                    </div>
                  );
                else {
                  return (
                    <div>
                      {this.props.user.bank_accounts.map((account, index) => {
                        return (
                          <div className="loan-card p-4" key={index}>
                            <div className="row">
                              <div className="col-sm-1">
                                <small className="text-muted">S/N</small>
                                <div className="P-3">{index + 1}</div>
                              </div>
                              <div className="col-sm-3">
                                <small className="text-muted">Bank Name</small>
                                <p className="font-weight-bold">{account.bank_name}</p>
                              </div>

                              <div className="col-sm-3">
                                <small className="text-muted">Account Name</small>
                                <p className="font-weight-bold">
                                  {account.name.toUpperCase()}
                                </p>
                              </div>
                              <div className="col-sm-3">
                                <small className="text-muted">Account Number</small>
                                <p className="font-weight-bold">{account.numba}</p>
                              </div>

                              <div className="col-sm-1">
                                <small className="text-muted">Linked</small>
                                <div>
                                  {account.linked === 1 ? (
                                    <i className="fa text-success fa-dot-circle" />
                                  ) : (
                                    <i className="fa text-muted fa-dot-circle" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          <div className="col-9">
            <h3 className="mb-0">HomeTown Account</h3>
            {this.props.user.reserved_accounts?.length ? (
              <>
                {this.props.user.reserved_accounts?.map((rAccount, index) => (
                  <div className="row p-4">
                    <div className="col-sm-1">
                      <small className="text-muted">S/N</small>
                      <div className="P-3">{index + 1}</div>
                    </div>
                    <div className="col-sm-3">
                      <small className="text-muted">Bank Name</small>
                      <p className="font-weight-bold">{rAccount.bank}</p>
                    </div>

                    <div className="col-sm-3">
                      <small className="text-muted">Account Name</small>
                      <p className="font-weight-bold">{rAccount.account_name}</p>
                    </div>
                    <div className="col-sm-3">
                      <small className="text-muted">Account Number</small>
                      <p className="font-weight-bold">{rAccount.account_number}</p>
                    </div>
                    {/* 
                                  <div className="col-sm-1">
                                    <small className="text-muted">Linked</small>
                                    <div>
                                      {rAccount.linked === 1 ? (
                                        <i className="fa text-success fa-dot-circle" />
                                      ) : (
                                        <i className="fa text-muted fa-dot-circle" />
                                      )}
                                    </div>
                                  </div> */}
                  </div>
                ))}
              </>
            ) : (
              <div className="p-5">
                <div className="text-center">
                  <h3>No HomeTown accounts for user</h3>
                </div>
              </div>
            )}
          </div>
          <div className="col-9">
            <h3 className="mb-0">Cards</h3>
            {/* <div> */}

            {this.props.user.cards?.length ? (
              <table className=" col-9 table">
                <thead>
                  <tr>
                    <th scope="col">Card Type</th>
                    <th scope="col">Bank</th>
                    <th scope="col">A/C Name</th>
                    <th>Added At</th>
                    <th scope="col">Expiration</th>
                  </tr>
                </thead>
                {this.props.user.cards.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>{JSON.parse(item.authorization).card_type.toUpperCase()}</td>
                      <td>{JSON.parse(item.authorization).bank}</td>
                      <td>{JSON.parse(item.authorization).account_name}</td>
                      <td>{item.created_at.split("T")[0]}</td>
                      <td>
                        {JSON.parse(item.authorization).exp_month}/
                        {JSON.parse(item.authorization).exp_year}
                      </td>
                      {(clearance == "loan-officer" || clearance == "admin") && (
                        <td>
                          <button
                            className={
                              this.props.disableButton || item.deleted_at
                                ? "btn btn-danger"
                                : "btn btn-warning"
                            }
                            onClick={() => this.handleCardDelete(item)}
                            disabled={item.deleted_at}
                          >
                            {/* { (this.props.disableButton ||item.deleted_at ) ? "Deleted":"Remove"}                         */}
                            {item.deleted_at !== null ? "Deleted" : "Remove"}
                          </button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                ))}
              </table>
            ) : (
              <div className="p-5">
                <div className="text-center">
                  <h3>No card added by user yet</h3>
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}
