import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      stopped_credit_bureau_accounts: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount() {
    this.getAccountsWithStoppedCB();
  }

  getAccountsWithStoppedCB() {
    this.setState({
      isLoading: true,
      stopped_credit_bureau_accounts: null,
      elements: null,
    });
    axios
      .post(
        ConstantVar.API_BASE_URL + "/support/get_accounts_with_stopped_cb",
        {
          token: localStorage.getItem("supportToken"),
        }
      )
      .then((response) => {
        let elements = response.data.data?.map((account) => (
          <tr>
            <td>{account.email}</td>
            <td>{account.fname}</td>
            <td>{account.lname}</td>
            <td>{account.phone}</td>
            <td>
              <button
                className="btn btn-info"
                onClick={() => this.stopCreditBureau(false, account.email)}
              >
                Start
              </button>
            </td>
          </tr>
        ));
        this.setState({
          isLoading: false,
          elements: elements,
          stopped_credit_bureau_accounts: 1,
        });
      });
  }

  stopCreditBureau = (stop, email) => {
    // if the request is to block the transfer
    if (stop) {
      //validate
      if (!email.length) {
        alert("Enter user email address");
        return false;
      }

      if (!email.includes("@") || !email.includes(".")) {
        alert("Enter a valid email address");
        return false;
      }
    }

    this.setState({
      isLoading: true,
      email: "",
    });

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/stop_credit_bureau", {
        token: localStorage.getItem("supportToken"),
        email: email,
        stop: stop,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.getAccountsWithStoppedCB();
        } else {
          self.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Transaction</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="email"
                className="form-control"
                placeholder=""
                // value={this.state.email}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button
              // onClick={() => this.stopCreditBureau(true, this.state.email)}
              className="mx-2 btn btn-success"
            >
              Stop
            </button>
          </div>

          <hr />

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Please wait....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.stopped_credit_bureau_accounts !== null ? (
            <div>
              <h4 className="p-4">Stopped</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Email Address</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">...</th>
                  </tr>
                </thead>
                <tbody>{this.state.elements}</tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Collection;
