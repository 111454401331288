import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";

const page = (
  <Router>
    <div>
      <Routes />
    </div>
  </Router>
);

ReactDOM.render(page, document.getElementById("root"));
serviceWorker.unregister();
